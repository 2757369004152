











































































import baseMxin from '@/mixins/baseMixin'
import gmapMixin from '@/mixins/gmapMixin'
import { Page } from '@/types/enums'
import { generateGmapRecord, sessionGet, sessionSet } from '@/utils'
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer'
import debounce from 'lodash/debounce'
import mixins from 'vue-typed-mixins'

export default mixins(gmapMixin, baseMxin).extend({
    name: 'PropertyMap',
    data() {
        return {
            scrollbarOptions: {
                scrollPanel: {
                    scrollingX: false
                },
                bar: {
                    onlyShowBarOnScroll: false
                    // background: colors.blue.lighten4
                },
                detectResize: true
            },
            propertyLocations: [] as any,
            zoom: 5,
            center: {
                lat: 49.8954,
                lng: -97.1385
            },
            queryBounds: [] as any,
            enableDrawingManager: false,
            panMapOnPin: false,
            openInfoWindowsOnPin: false,
            initializeOnMount: false,
            search: '',
            debouncedAddNewMarkersToMap: (newValue:any) => newValue as any,
            mapHeight: 0,
            recordPage: 0,
            maxRecords: 10
        }
    },
    created() {
        this.debouncedAddNewMarkersToMap = debounce(this.addNewMarkersToMap, 500).bind(this)
    },
    async mounted() {
        const sessionCenter = sessionGet('center') as any
        const sessionZoom = sessionGet('zoom')

        if (sessionCenter && !isNaN(sessionCenter.lat) && !isNaN(sessionCenter.lng)){ 
            this.center = sessionCenter
        } else {
            window.sessionStorage.removeItem('center')
        }

        if (sessionZoom && !isNaN(parseInt(sessionZoom))) {
            this.zoom = parseInt(sessionGet('zoom'))
        } else {
            window.sessionStorage.removeItem('zoom')
        }

        await this.initialize(this.$refs.map as HTMLElement)
        this.map?.addListener('zoom_changed', this.updateZoom.bind(this))
        this.map?.addListener('center_changed', this.updateCenter.bind(this))
        this.map?.addListener('bounds_changed', debounce(this.boundsChanged, 500).bind(this))
    },
    methods: {
        onRecordPageChange(value: number) {
            this.recordPage = value - 1;
            (this.$refs.scrollable as HTMLElement)?.scrollTo({top: 0, behavior: 'smooth'})
        },
        getMapHeight() {
            const toolbarElement = document.querySelector('.v-toolbar') as HTMLElement
            if (!toolbarElement)
                return window.innerHeight - 164

            return window.innerHeight - toolbarElement.clientHeight - 168
        },
        onResize() {
            this.mapHeight = this.getMapHeight()
        },
        boundsChanged(): void {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const bounds = this.map.getBounds()!
            

            const ne = bounds.getNorthEast()
            const sw = bounds.getSouthWest()
            
            this.queryBounds = [
                [ne.lng(), ne.lat()],
                [sw.lng(), sw.lat()]
            ]

            this.getPropertyLocations()
        },
        async getPropertyLocations() {
            this.showPageLoading(true)
            try {
                let params = {
                    boundNE: this.queryBounds[0],
                    boundSW: this.queryBounds[1],
                    keyword: this.search ?? ''
                } as any

                const res = await this.axios.post('/Property/GetPropertyLocations', params)

                const data = res.data
                
                this.propertyLocations = data
                this.onRecordPageChange(1)
                this.debouncedAddNewMarkersToMap(data)
            } catch (err) {
                console.log(err)
            } finally {
                this.showPageLoading(false)
            }
        },
        searchList: debounce(function() {
            // @ts-ignore
            if (this.search == null || this.search.length > 1 || this.search.length === 0) {
                // @ts-ignore
                this.getPropertyLocations()
            }
        }, 300),
        updateZoom() {
            sessionSet('zoom', this.map?.getZoom() ?? this.zoom, 1440)
        },
        updateCenter() {
            const center = this.map?.getCenter() ?? { lat: () => this.center.lat, lng: ()=> this.center.lng}
            sessionSet('center', {lat: center.lat(), lng: center.lng()}, 1440)
        },
        addNewMarkersToMap(newValue: any) {
            this.clearMarkers()
            const markers = newValue.map((value:any) => {       
                const geoCodedRecord = {
                    ...generateGmapRecord({
                        recordSetting: this.gmapRecordSetting, 
                        dynamicObj: value, 
                        id: value.property, 
                        gmapIconSetting: this.gmapIconSetting,
                        page: Page.PROPERTY,
                        enableUrl: true
                    }),
                    latitude: value.latitude,
                    longitude: value.longitude,
                }
                        
                const geolocation = this.convertoLatLng(geoCodedRecord)
                if(this.panMapOnPin) {
                    this.bounds.extend(geolocation)
                }

                if(this.enableDrawingManager) {
                    this.markers.push({
                        id: geoCodedRecord.id,
                        location: geolocation,
                        isSelected: false
                    })
                }
                
                return this.createMarker(geoCodedRecord, false)
            })
            this.markerClusterer = new MarkerClusterer(
                {
                    map: this.map,
                    algorithm: new SuperClusterAlgorithm({maxZoom: this.clusterOn ? 19 : 0, radius: 300}),
                    markers: markers
                }
            )
        }
    },
    watch:{
        search() {
            this.searchList()
        }
    },
    computed: {
        pageRecordStartingIndex() : number {
            return this.recordPage * this.maxRecords
        },
        pageRecordEndingIndex(): number {
            return this.pageRecordStartingIndex + this.maxRecords
        },
        numberOfPages(): number {
            return Math.ceil(this.propertyLocations.length / this.maxRecords)
        }
    }
})
