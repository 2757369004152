















import PropertyLocationsMap from '@/components/PropertyLocationsMap.vue'
import UnconfiguredGmapAlert from '@/components/UnconfiguredGmapAlert.vue'
import { Page } from '@/types/enums'
import { GmapRecordSetting, StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { initGmaps } from '@/utils'
import Vue from 'vue'

export default Vue.extend({
    name: 'PropertyMapView',
    components: {
        PropertyLocationsMap,
        UnconfiguredGmapAlert
    },
    data() { 
        return {
            pageLoading: true,
            gmapOptions: {
                page: Page.MAP,
            },
            gmapIconSetting: {} as StackGmapIconSetting,
            gmapRecordSetting: {} as GmapRecordSetting,
            isMapConfigured: false
        }
    },
    async created() {
        await initGmaps.call(this)
        this.pageLoading = false
    }
})
